import React from 'react';

const InsuranceOffer = () => {
  return (
    <div className="insurance-container">
      <p>
      ESTIMADO TRADER, DEBIDO A SU EXCELENTE CONSISTENCIA EN LAS OPERACIONES, SE LE OFRECE LA OPORTUNIDAD DE ADQUIRIR UN SEGURO PARA SU CUENTA. ESTE SEGURO DEBE RECLAMARSE ANTES DE LA FECHA DE RECOMPENSA Y TIENE UN COSTO DE $2,794. SU FUNCIÓN ES PROTEGER SU CUENTA FRENTE A UNA EVENTUAL PÉRDIDA Y ASEGURAR SU BENEFICIO ACTUAL EN UNA ÚNICA OCASIÓN. EN CASO DE QUE SU CUENTA ALCANCE LOS LÍMITES PERMITIDOS DE PÉRDIDA, SERÁ RESTAURADA JUNTO CON EL BENEFICIO ACUMULADO HASTA ESE MOMENTO. LE RECOMENDAMOS APROVECHAR ESTA OFERTA PARA ASEGURAR LA CONTINUIDAD DE SUS OPERACIONES CON MAYOR TRANQUILIDAD.
      </p>
    </div>
  );
};

export default InsuranceOffer;
